<template lang="html">
  <div>
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.produto', 1) }}</h3>
    </v-card-title>
    <v-row justify="start">
      <v-col cols="12" sm="6" md="3" class="ml-3">
        <v-text-field
          v-model="searchQuery"
          @input="filtrar"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" align-self="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon
              id="produto-botao-ativar-inativar"
              v-show="canAccessCRUD && selected.length > 0"
              class="mx-0"
              v-on="on"
              @click="ativarDesativarRegistros">
              <v-icon>block</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.ativar_inativa') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon
              id="produto-botao-importar"
              v-show="canAccessCRUD && tipoImportacao"
              class="mx-0"
              v-on="on"
              :disabled="!importacao || bloquearBotaoExportacao"
              @click="importarDados">
              <v-icon>backup</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.importar_conteudo') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon
              id="produto-botao-exportar"
              v-show="canAccessPage && searchQuery !== null && searchQuery.length"
              class="mx-0"
              v-on="on"
              :disabled="!exportacao || bloquearBotaoExportacao"
              @click="exportarDados">
              <v-icon>get_app</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.exportar_conteudo') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersDinamico"
      :items="produtos"
      v-model="selected"
      show-select
      class="tableProdutos"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item="{ item, isSelected, select }">
        <tr :key="item.idExterno" :class="{ 'TableRow--disabled': !item.indAtivo }">
          <td style="width: 2%;">
            <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
          </td>
          <td class="justify-center px-0" style="width: 2%;">
            <v-tooltip bottom v-if="canEdit">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="editarItem(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.editar_produto') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!canEdit && canGet">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="visualizarItem(item)">
                  <v-icon>search</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc('label.visualizar_produto', 1) }}</span>
            </v-tooltip>
          </td>
          <td nowrap style="width: 5%;">{{ item.idExterno }}</td>
          <td style="width: 15%;">{{ item.nomProduto }}</td>
          <td nowrap style="width: 5%;">{{ item.ean }}</td>
          <td style="width: 5%;" v-for="(entidade, indice) in Object.keys(headersDinamicoObrigatorio)" :key="indice">
            {{ getNomesSeparadosPorVirgula(entidade, 'nomExtensao', item) }}
          </td>
          <td nowrap style="width: 5%;" v-if="true">{{ item.idExternoPai }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import * as metadados from '../../common/functions/metadados';
import basePath from '../../common/functions/api-resource';

export default {
  props: {
    exportacao: false,
    importacao: false,
  },
  data() {
    return {
      bloquearBotaoExportacao: false,
      esperar: false,
      headers: [
        { text: this.$tc('label.cod_produto', 1), value: 'id_externo', sortable: true },
        { text: this.$tc('label.produto', 1), value: 'nom_produto', sortable: true },
        { text: this.$tc('label.ean', 1), value: 'ean', sortable: true },
      ],
      produtos: [],
      pagination: {},
      totalPage: 0,
      resourcesMetadado: this.$api.metadado(this.$resource),
      resourcesProduto: this.$api.produto(this.$resource),
      searchQuery: null,
      selected: [],
      timeout: 0,
      limiteRegistrosExportacao: 200000,
    };
  },
  computed: {
    ...generateComputed('prd', [
      'canAccessCRUD',
      'canAccessPage',
      'canEdit',
      'canGet',
    ]),
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    headersDinamico() {
      const headers = Object.assign([], this.headers);
      if (!this.getProdutoMetadado) return headers;

      Object.keys(this.getProdutoMetadado.mapaEntidades)
        .sort(this.ordenarMetadados)
        .forEach((entidade) => {
          const mapeamentoEntidade = this.getProdutoMetadado.mapaEntidades[entidade];
          headers.push({
            text: mapeamentoEntidade.entidadeEstrangeira,
            value: mapeamentoEntidade.label,
            sortable: false,
          });
        });
      if (this.canEdit || (!this.canEdit && this.canGet)) {
        headers.unshift({
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        });
      }
      headers.push({
        text: this.$t('label.cod_produto_pai'),
        value: 'idExternoPai',
        sortable: false,
        width: '5%',
      });
      return headers;
    },
    headersDinamicoObrigatorio() {
      const headers = {};
      Object.keys(this.getProdutoMetadado.mapaEntidades)
        .sort(this.ordenarMetadados)
        .forEach((entidade) => {
          const mapeamentoEntidade = this.getProdutoMetadado.mapaEntidades[entidade];
          if (mapeamentoEntidade.depObrigatoria) {
            headers[entidade] = mapeamentoEntidade;
          } else {
            headers[entidade] = false;
          }
        });
      return headers;
    },
    somenteLeitura() {
      return !this.canEdit && this.canGet;
    },
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_produto');
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 1500);
    },
  },
  methods: {
    ...metadados,
    ...mapActions('metadados', [
      'setProdutoMetadado',
    ]),
    ordenarMetadados(chave1, chave2) {
      const ind1 = this.getProdutoMetadado.mapaEntidades[chave1].indiceRecursao !== undefined
        ? this.getProdutoMetadado.mapaEntidades[chave1].indiceRecursao : 50000;
      const ind2 = this.getProdutoMetadado.mapaEntidades[chave2].indiceRecursao !== undefined
        ? this.getProdutoMetadado.mapaEntidades[chave2].indiceRecursao : 50000;
      return ind1 > ind2 ? -1 : 1;
    },
    ativarDesativarRegistros() {
      this.requestAtivarInativar();
    },
    requestAtivarInativar() {
      if (this.selected && this.selected.length > 0) {
        this.resourcesProduto.ativarMassa(this.selected)
          .then(() => {
            if (this.selected.length > 1) {
              this.$toast(this.$t('message.alteracao_massa_sucesso'));
            } else {
              this.$toast(this.$t('message.alteracao_sucesso'));
            }
            this.selected = [];
            this.refreshViewProduto();
            this.buscar();
          })
          .catch((err) => this.$error(this, err));
      }
    },
    refreshViewProduto() {
      const path = `${basePath('api', 'compilador/views/produto')}/refresh`;
      return this.executar(path);
    },
    executar(path) {
      return this.$http.post(path)
        .then(() => {
        });
    },
    buscar() {
      const params = {
        filter: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0] ? this.pagination.sortBy[0] : 'id_externo',
      };

      this.resourcesProduto.listar(params).then((response) => {
        this.produtos = response.data.resposta;
        this.montarObjetoProduto(response.data.resposta);
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    editarItem(row) {
      this.$emit('PRODUTOTABELA_EDITAR', row);
    },
    exportarDados() {
      if (this.totalPage > this.limiteRegistrosExportacao) {
        this.$toast(this.$tc('message.exportacao_quantidade_registros'));
        return;
      }
      this.bloquearBotaoExportacao = true;
      this.$emit('TABELA_EXPORTAR_DADOS',
        () => { this.bloquearBotaoExportacao = false; });
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    getSearchQuery() {
      if (this.searchQuery && this.searchQuery.length > 0) {
        return this.searchQuery;
      }
      return null;
    },
    importarDados() {
      this.$emit('TABELA_IMPORTAR_DADOS');
    },
    montarObjetoProduto(dados) {
      this.produtos = dados
        .map((data) => metadados.montarEstruturaDinamica(data, this.getProdutoMetadado, data));
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    requestPopularHeader() {
      if (this.getProdutoMetadado) {
        return;
      }
      this.setProdutoMetadado({
        resource: this.resourcesMetadado.listarProduto,
      });
    },
    visualizarItem(row) {
      this.$emit('PRODUTOTABELA_VISUALIZAR', row);
    },
  },
  mounted() {
    this.requestPopularHeader();
  },
};
</script>
<style>
  .tableProdutos .v-data-table__wrapper table {
    width: 120%;
  }
</style>
